import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageTraitementImagesVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "traitementImagesVoieLactee" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "photographierArcheVoieLactee"
            "voieLacteeIntegralite360"
            "megalaxyZoom"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeIntegralite"
            "voieLacteePretraitementdesImagesDeVoieLacteeDansLigthroom"
            "voieLacteeSelectiondeLaVoieLacteeDansPhotoshop"
            "photoshopTraitementVoieLacteeFinal"
            "sequatorChargementEtOptions"
            "sequatorSelectionCielEtSol"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeVoieLacteeAvecDuBruit"
            "voieLacteeVoieLacteeSansBruit"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function TraitementImagesVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageTraitementImagesVoieLactee")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Ce tutoriel détaille le traitement des images de paysage nocturnes de
          Voie lactée sous Photoshop et Lightroom. Si vous n’avez jamais pris la
          Voie lactée en photo, je vous invite tout d’abord à lire l’article
          consacré à la photographie de la Voie lactée que j’ai écrit{" "}
          <Lien codePageCible="photographierLaVoieLactee">ici</Lien>.
        </p>
        <p>
          La technique décrite ici est surtout utile si{" "}
          <strong>votre ciel est pollué</strong> ou si{" "}
          <strong>votre appareil photo n’est pas très sensible</strong>. Pour la
          suivre, vous devez avoir réalisé une série de prises de vues
          identiques afin de diminuer le bruit de votre image de Voie lactée
          (consultez l’article qui est en lien ci-dessus).
        </p>
        <p>
          Après un petit mot sur l’éthique de l’astrophotographe, nous
          procéderons en 3 étapes :
        </p>
        <ul>
          <li>Prétraitement dans Lightroom.</li>
          <li>Traitement dans Sequator.</li>
          <li>Retouches esthétiques finales dans Photoshop.</li>
        </ul>
        <Image
          image={obtenirImage(images, "voieLacteeIntegralite")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Éthique de l’astrophotographe">
        <p>
          Attention, la technique décrite dans cet article soulève un problème{" "}
          <strong>d’éthique astrophotographique</strong>.
        </p>
        <p>
          En mélangeant techniques de ciel profond (empilement de poses pour
          faire baisser le bruit) et de photographie conventionnelle, les
          résultats obtenus peuvent s’apparenter plus à du{" "}
          <strong>graphisme</strong> qu’à de la photographie. En effet, si le
          dosage est mal réalisé, le résultat peut franchement s’éloigner de la
          réalité. Ainsi, de plus en plus d’astrophotographes poussent le vice
          en réalisant des montages grossiers d’une Voie lactée cumulant
          plusieurs minutes de poses avec un premier plan provenant d’un autre
          cliché.
        </p>
        <p>
          Ces pratiques conduisent de plus en plus à mauvaise image de
          l’astrophotographie auprès du public (« photos truquées »).
        </p>
        <p>Pour éviter ça, s’il vous plaît :</p>
        <ul>
          <li>
            Utilisez la technique décrite dans cet article à{" "}
            <strong>bon escient</strong> (ciel pollué, boîtier peu sensible,
            optique qui n’est pas suffisamment ouverte, démarche artistique…).
          </li>
          <li>
            <strong>Soyez honnête</strong> en précisant s’il s’agit d’un
            empilement de poses lors de la diffusion de votre image.
          </li>
          <li>
            Ne réalisez pas de <strong>montage</strong> en utilisant un premier
            plan qui ne se trouve pas à l’endroit où vous avez pris votre ciel.
            Ou alors, précisez-le lors de la diffusion de votre image.
          </li>
          <li>
            Ne vous <strong>éloignez pas </strong>(trop) de la réalité
          </li>
          <li>
            Ne <strong>sur-traitez</strong> pas votre image : les images
            traitées simplement sont bien souvent les meilleures.
          </li>
        </ul>
        <p>
          Personnellement, je préfère maintenant utiliser des optiques très
          ouvertes (ex : un 24 mm F1.4, à condition de le diaphragmer un peu)
          sous les meilleurs cieux possibles. Ce qui implique de se déplacer,
          mais on a rien sans rien ;)
        </p>
        <p>
          Vous pouvez attendre aussi qu’un petit croissant de Lune éclaire votre
          premier plan…
        </p>
      </Section>
      <Section titre="Le prétraitement des images">
        <p>
          En astrophotographie, le but du <strong>prétraitement</strong> est de
          créer une image possédant{" "}
          <strong>le moins de défauts que possible</strong> (bruit, défauts
          optiques…) à partir d’une série de photos identiques. Cette image sera
          utilisée pour réaliser les retouches esthétiques finales (couleurs,
          netteté, etc.). Ce procédé est nécessaire, car les corrections
          esthétiques dégradent très vite l’image, surtout si elle a été prise
          dans l’obscurité.
        </p>
      </Section>
      <Section titre="Prétraitement des images dans Lightroom">
        <p>
          Localisez la série d’images que vous utiliserez pour créer votre image
          finale. Sur une de vos images, corrigez l’exposition et ses défauts
          optiques (distorsions et surtout vignetage).
        </p>
        <p>
          <strong>ATTENTION</strong> : ne réduisez surtout pas le bruit à cette
          étape pour ne pas perdre de détails. Vous le ferez en toute fin de
          processus, une fois les retouches esthétiques réalisées.
        </p>
        <p>
          Synchronisez ensuite ces paramètres avec les autres images de votre
          série (dans la bibliothèque : « Synch param » en bas à droite). Une
          fois ceci réalisé, exportez vos images au format TIFF (n’utilisez pas
          le format JPEG).
        </p>
        <Image
          image={obtenirImage(
            images,
            "voieLacteePretraitementdesImagesDeVoieLacteeDansLigthroom"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Dans Sequator">
        <p>
          Les images sont maintenant{" "}
          <strong>débarrassées de leurs défauts optiques</strong>, mais elles
          sont toujours bruitées. Si vous débutez les traitements esthétiques
          maintenant, vous allez fortement faire monter le bruit. Nous allons
          donc les empiler pour diminuer le bruit et avoir plus de marge de
          manœuvre lors du traitement de l’image finale.
        </p>
        <p>
          Sans trop entrer dans les détails (la réalité est plus complexe), en
          empilant les images,{" "}
          <strong>vous allez simuler une pose plus longue</strong>, donc avec
          plus de signal et moins de bruit. Avec 3 images de 30 secondes, vous
          aurez un résultat similaire à une pose de 1 minute 30 secondes. Il
          sera même meilleur car la pollution lumineuse se verra moins, et si
          vous n’avez pas de dispositif de suivi, cela vous permettra d’avoir
          étoiles ponctuelles.
        </p>
        <p>J’utilise le même principe en astrophotographie du ciel profond.</p>
        <p>Les manipulations à réaliser sont les suivantes :</p>
        <ul>
          <li>
            Téléchargez et installez le logiciel{" "}
            <Lien urlExterne="https://sites.google.com/site/sequatorglobal/">
              Sequator
            </Lien>{" "}
            (Windows seulement) qui vous permettra d’empiler vos prises de vues.
            Si vous êtes sous Mac, vous pouvez utiliser le logiciel{" "}
            <Lien urlExterne="https://sites.google.com/site/starrylandscapestacker/home">
              Starry Landscape Stacker
            </Lien>
            .
          </li>
          <li>
            Dans la rubrique « Base image » (1), sélectionnez la première image
            de votre série.
          </li>
          <li>
            Dans la rubrique « Star images » (2), sélectionnez toutes les images
            de votre série.
          </li>
          <li>
            Dans la rubrique « Composition » (3), choisissez la méthode « Freeze
            ground ».
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "sequatorChargementEtOptions")}
          langue={page.langue}
        />
        <p>
          <strong>
            Nous allons maintenant indiquer à Sequator où se trouve le ciel
            étoilé.
          </strong>{" "}
          Pour cela, cliquez sur « Sky Region » (1). Choisissez alors l’option «
          Irregular mask » (2), puis peignez en vert la région correspondant au
          ciel (3). Si le pinceau pour peindre le ciel étoilé en vert est trop
          grand, utilisez le raccourci « Ctrl + roulette de souris ».
        </p>
        <Image
          image={obtenirImage(images, "sequatorSelectionCielEtSol")}
          langue={page.langue}
        />
        <p>
          Lancez maintenant l’empilement en cliquant sur « Start » (4). Vous
          obtenez alors une image « prétraitée » d’excellente qualité : elle est
          peu bruitée et débarrassée d’une majorité des défauts de votre optique
          grand-angle.
        </p>
        <p>Comparons le résultat avant – après :</p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeVoieLacteeAvecDuBruit")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteeVoieLacteeSansBruit")}
            langue={page.langue}
          />
        </div>
        <p>
          Même si la différence ne semble pas énorme, je vous assure que ça
          compte <strong>énormément</strong> pour pouvoir faire un traitement
          esthétique de qualité. En plus il n’y a qu’1 minute et 30 secondes de
          pose. Imaginez le résultat avec 5 minutes de poses, c’est encore mieux
          !
        </p>
      </Section>
      <Section titre="Traitement dans Photoshop">
        <p>
          À vous de jouer maintenant ! Ouvrez dans Photoshop{" "}
          <strong>
            le fichier résultat de l’empilement réalisé avec Sequator
          </strong>
          . Commencez par séparer le sol et le ciel étoilé à l’aide d’un masque
          de fusion. Puis, sur le calque correspondant au ciel étoilé :
        </p>
        <ul>
          <li>
            Réalisez une sélection de la Voie lactée avec un contour progressif
            de 200 ou 300 pixels à l’aide de l’outil « Lasso ».
          </li>
          <li>Augmentez la luminosité et le contraste de la Voie lactée.</li>
          <li>
            Inversez la sélection en utilisant le raccourci clavier « Ctrl +
            Shift + i ». La sélection contient maintenant le ciel étoilé sans la
            Voie Lactée.
          </li>
          <li>
            Baissez la luminosité et augmentez le contraste de cette sélection
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "voieLacteeSelectiondeLaVoieLacteeDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>Ensuite :</p>
        <ul>
          <li>
            Donnez une légère dominante bleue à votre image si nécessaire grâce
            à l’outil de réglage de la balance des couleurs.
          </li>
          <li>Augmentez l’exposition et la saturation de votre image.</li>
          <li>
            Ajoutez éventuellement un peu de vignetage « artistique » (menu «
            Filtre » puis « Correction de l’objectif ») afin d’attirer
            l’attention du spectateur sur la Voie lactée.
          </li>
          <li>
            Augmentez la luminosité et la saturation du bulbe galactique en le
            sélectionnant avec un lasso doté d’un contour progressif important
            (200 pixels par exemple).
          </li>
          <li>
            Terminez votre traitement ajoutant une légère touche de netteté
            (menu « Filtre » puis « Netteté optimisée »).
          </li>
        </ul>
        <p>
          Enfin, dernier conseil, une fois votre image réalisée, ne la montrez
          pas tout de suite. Revenez-y quelques heures plus tard. Vous y
          trouverez sans doutes quelques défauts à corriger…
        </p>
        <p>Je parviens au résultat suivant après ces manipulations :</p>
        <Image
          image={obtenirImage(images, "photoshopTraitementVoieLacteeFinal")}
          langue={page.langue}
        />
      </Section>
    </LayoutTutorielFR>
  )
}
